<template>
  <b-card no-body>
      <b-card-body>
        <h3>Time Sheets</h3>            
            <b-col class="text-right">
                <b-button variant="primary" @click="exportPDF"   @dblclick="exportPDF">
                    <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"
                    />PDF</b-button
                >
                <b-button variant="info" @click="csvExport" class="ml-1">
                                <feather-icon
                                    icon="DownloadIcon"
                                    class="mr-50"
                                />CSV</b-button>
            </b-col>
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="From Date"
              label-for="From Date"
            >
              <b-form-datepicker
                id="from-date"
                label="label"
                v-model="startDate"
                class="mb-1"
                placeholder="Start Date"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="To Date"
              label-for="To Date"
            >
              <b-form-datepicker
                id="to-date"
                label="label"
                v-model="endDate"
                class="mb-1"
                placeholder="End Date"
              />
            </b-form-group>
          </b-col>

          <b-col cols="3">
              <b-form-group label="Driver" label-for="driver">
               
                <v-select
                                v-model="driver"
                                label="label"
                                :options="option"
                                @input="onChange()"
                                placeholder="Select Driver"
                            />
  
              </b-form-group>
            </b-col>
  
          <b-col md="3">
                <div>
                    <b-form-group label="Action" label-for="action">
                    <b-button variant="primary" @click="filterAllData"
                        ><feather-icon icon="filterIcons" /> Filter</b-button
                    >

                    <b-button variant="info" @click="resetAllData" class="ml-1">
                      <feather-icon icon="filterIcons" /> Reset</b-button>
                    </b-form-group>
                </div>
          </b-col>
        </b-row>
      
        <div></div>
      </b-card-body>
     
      <b-overlay :show="showUpdate" rounded="sm">
        <b-card>
        <b-card-body class="p-0">
      <div class="d-flex justify-content-center">
                <div style="width: 100%" >
                    <pdfexport ref="contentToExport" :fileName="pdfFileName" forcePageBreak=".page-break" :margin="'0.5cm'" :paper-size="'A4'" :keepTogether="'.prevent-split'" :enable-download="'true'">
                        <b-overlay
                            :show="show"
                            rounded="sm"
                            >
                                <ContentToPrint :data="data" :startDate="startDate" :endDate="endDate" :fileName="pdfFileTitle" :dateRangeBetween="dateRange" :status="pdfStatus"/>                        
                                <div class="page-break"></div>
                        </b-overlay>
                    </pdfexport>
                </div>
            </div>
                     
        </b-card-body>
      </b-card>
      </b-overlay>
  </b-card>
  
</template>

<script>

import {
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BTable,
  BFormCheckbox,
  BPagination,
  BOverlay,
  BFormInvalidFeedback,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
// import 'vue-good-table/dist/vue-good-table.css'
import { heightTransition } from "@core/mixins/ui/transition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ContentToPrint from "./ContentToPrint";
import { PDFExport } from "@progress/kendo-vue-pdf";
import axios from "axios";
import moment from "moment";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import exportFromJSON from "export-from-json";

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTable,
    BFormCheckbox,
    BPagination,
    vSelect,
    BOverlay,
    BFormInvalidFeedback,
    BDropdownItem,
    BDropdown,
    ContentToPrint,
    pdfexport: PDFExport,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      driver: '',
      option: [],
      showUpdate : false,
      pdfFileName : getUserData().company_name+' - Time Sheets',
      pdfFileTitle : getUserData().company_name,
      dateRange : '',
      show : false,
      perPage: 25,
      pageOptions: [5, 10, 15, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: " ",
      history_list: [],
      filterOn: [],
      data:[],
      formattedData :[],
      selectedDriver : '',
      selectedDriverName : '',
      startDate:'',
      endDate:'',
      pdfStatus : false,
      companyId: getUserData().company_id,
      fields: [
      {
          key: "driver_name",
          label: "Driver Name",
          formatter: (value) => {
            return value.toUpperCase();
          },
        },
        {
          key: "date",
          label: "Date",
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
       
        {
          key: "clock_in",
          label: "Clock IN",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("HH:mm:ss");
          },
        },
        {
          key: "date",
          label: "Date",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "date_out",
          label: "Date",
          sortable: false,
          formatter: (value) => {
            return value== null ? '-' : moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "clock_out",
          label: "Clock Out",
          sortable: false,
          formatter: (value) => {
            return value== null ? '-' : moment(value).format("HH:mm:ss");
          },
        },
        
        {
          key: "total_time",
          label: "Hours Worked",
          sortable: false,
          formatter: (value) => {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours} hours and ${minutes} minutes`;
          },
        },
        {
          key: "action",
        },
      ],
      
    };
  },
  mounted() 
  {
    var d = new Date();
    this.endDate     = moment(d.setDate(d.getDate())).format("YYYY-MM-DD HH:mm:ss");
	  this.startDate   = moment(d.setDate(d.getDate() - 7)).format("YYYY-MM-DD") + ' 00:00:00';

    this.showUpdate = true;

            var DateRange = '';
            var DateRangeNew = '';
            if(this.startDate && this.endDate)
            {
              DateRange = ' - '+this.startDate+" - "+this.endDate;
              DateRangeNew = this.getFormattedDate(this.startDate)+" - "+this.getFormattedDate(this.endDate);

            } else {
              DateRange = '';
              DateRangeNew = '';
            }

        this.dateRange = DateRangeNew == '' ? '' : 'Time Sheets: '+DateRangeNew;
        this.pdfFileName = getUserData().company_name+' - Time Sheets'+DateRange;
        this.pdfFileTitle = getUserData().company_name;
        
    this.getClockHistory();
  },
  created() {

    axios.get(`${serverUri}/drivers/alldriversdata/${this.companyId}`).then((res) => {
            res.data.map((item) => {
                this.option.push({ title: item.id, label: item.driver_name });
            })
        })
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {

    exportPDF: function() {
            this.pdfStatus = true;
            // console.log(this.$refs.contentToExport);
            this.$refs.contentToExport.save();
            this.pdfStatus = false;
           
        },

    getClockHistory() {

      this.$http
        .post(`${serverUri}/time-sheet-history`, {
            company_id:this.companyId,
            driver_id: this.selectedDriver,
            start_date:this.startDate,
            end_date:this.endDate
        })
        .then((response) => {
          this.data = { driver:this.selectedDriverName,start_date:this.startDate,end_date:this.endDate,data:response.data.data};
          response.data.data.map((item) => {
                this.formattedData.push({ 'Driver Name': item.driver_name.toUpperCase(), 'Date': this.getFormattedDate(item.date), 'Clock IN': this.getFormattedDateTime(item.clock_in),'Date Out': this.getFormattedDate(item.date_out),'Clock Out': this.getFormattedDateTime(item.clock_out),'Total Time': this.getFormattedTotalTime(item.total_time) });
            })
          this.showUpdate = false;
          if (response.data.status == true) 
          {
            this.history_list = response.data.data;
          } else {
            this.history_list = [];
          }
        });
    },
        getFormattedDate(date) {
            return moment(date).format("MM/DD/YYYY");
        },
        getFormattedDateTime(date) {
            return moment(date).format("HH:mm:ss");
        },    
        getFormattedTotalTime(value)
        {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours} hours and ${minutes} minutes`;
        },    

    csvExport() {   
          var DateRange = '';
            if(this.startDate && this.endDate)
            {
              DateRange = ' - '+this.startDate+" - "+this.endDate;
            } else {
              DateRange = '';
            }

            const fileName = getUserData().company_name+" - Time Sheets"+DateRange;
            const data = this.formattedData;
            const exportType = exportFromJSON.types.csv;

            if (data) exportFromJSON({ data, fileName, exportType });
        },

    filterAllData()
    {
        this.showUpdate = true;

            var DateRange = '';
            var DateRangeNew = '';
            if(this.startDate && this.endDate)
            {
              DateRange = ' - '+this.startDate+" - "+this.endDate;
              DateRangeNew = this.getFormattedDate(this.startDate)+" - "+this.getFormattedDate(this.endDate);

            } else {
              DateRange = '';
              DateRangeNew = '';
            }

        this.dateRange = DateRangeNew == '' ? '' : 'Time Sheets: '+DateRangeNew;
        this.pdfFileName = getUserData().company_name+' - Time Sheets'+DateRange;
        this.pdfFileTitle = getUserData().company_name;

        this.getClockHistory();
    },

    resetAllData()
    {
        this.showUpdate = true;
        this.startDate = '',
        this.endDate = '',
        this.driver = {title : '', label: ''},
        this.selectedDriver = '',

        this.getClockHistory();
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      // this.$nextTick(() => {
      //     this.trSetHeight(this.$refs.form.scrollHeight);
      // });
    },

    selectionChanged(params) {
      this.selectedItems = params.selectedRows;
    },

    changeYear() {
      this.getFilteredMMR();
    },

    changeMonth() {
      this.getFilteredMMR();
    },

    onChange() {
        this.selectedDriver = this.driver?.title;
        this.selectedDriverName = this.driver?.label;
    },
    // exportPDF(data) {
    //   axios
    //     .post(`${serverUri}/fleet/getselectedmaintenance`, {
    //       tractor_id: data.formattedRow.tractor_id,
    //       domicile: data.formattedRow.domicile,
    //       month: moment().month(data.formattedRow.month).format("M"),
    //     })
    //     .then(async (res) => {
    //       var temp = await res.data;

    //       if (temp.length) {
    //         this.mmrData = {
    //           signature: this.signature,
    //           monthYear: this.selectedMonth + " " + this.selectedYear,
    //           domicile: data.formattedRow.domicile,
    //           serviceName: data.formattedRow.name,
    //           odometer: data.formattedRow.odometer
    //             ? Math.round(data.formattedRow.odometer)
    //             : "",
    //           tractor: data.formattedRow.tractor_id,
    //           mmrTask: data.formattedRow.mmr_task,
    //           oos: data.formattedRow.out_of_service,
    //           tArr: temp,
    //           dateCompleted: moment(this.selectedDate).format("MM/DD/YYYY"),
    //         };

    //         setTimeout(() => {
    //           this.$refs.contentToExport.save();
    //         }, 1000);
    //       }
    //     });
    // },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vgt-table th {
  font-size: 13px;
}

.hiddenContainer {
  position: absolute;
  left: -10000px;
  top: 0;
}
button.btn.btn-danger {
    margin-left: 10px;
}
</style>
