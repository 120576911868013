<template>
  <div>
    <div>
        <div>
            <!-- <h5 >Time Sheet History List</h5> -->
        </div>
        <div style="font-size: 10px;">
            <!-- <div class="d-flex align-item-center justify-content-between">
                <div style="margin-bottom: 5px;">
                    Driver: <span>{{ data.driver }}</span><br>
                    From Date: <span>{{ startDate }}</span><br>
                    To Date: <span>{{ endDate ? endDate : startDate }}</span><br>
                </div>
            </div> -->
            <h2 style="text-align:center">{{ fileName }}</h2>
            <h4 style="text-align:center">{{ dateRangeBetween }}</h4>
            <table>
                <tr>
                    <th width="100px;">Driver Name</th>
                    <th>Clock IN</th>
                    <th>Clock Out</th>
                    <th>Total Time</th>
                </tr>
                <tr v-for="(n, i) in data.data.length" :key="i">
                    <td width="100px;" v-if="status == true" style="word-wrap: break-word;">
                        {{ data.data[i].driver_name.toUpperCase() }}
                    </td>
                    <td width="100px;" v-if="status == false">
                        <router-link :to="{ name: 'apps-reports-edit-timesheets', params: { id: data.data[i].id, driver_id: data.data[i].driver_id }}">{{ data.data[i].driver_name.toUpperCase() }}</router-link>
                    </td>
                    <td>
                        {{ getFormattedDate(data.data[i].date)+' '+getFormattedDateTime(data.data[i].clock_in) }}
                    </td>
                    <td>
                        {{ data.data[i].date_out == null ? '-' :  getFormattedDate(date_out)+' '+getFormattedDateTime(data.data[i].clock_out) }}
                    </td>
                    <td>
                        <span v-if="data.data[i].total_time >= 50400">
                            <p style="color:red">{{ getFormattedTotalTime(data.data[i].total_time) }}</p>
                        </span>

                        <span v-if="data.data[i].total_time < 50400">
                           {{ getFormattedTotalTime(data.data[i].total_time) }}
                        </span>

                        
                    </td>
                </tr>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
    
export default {
    props: {
        status:String,
        fileName:String,
        data: Object,
        startDate: String,
        endDate: String,
        dateRangeBetween: String
    },
    methods: {
        getFormattedDate(date) {
            return moment(date).format("MM/DD/YYYY");
        },
        getFormattedDateTime(date) {
            return moment(date).format("HH:mm a");
        },    
        getFormattedTotalTime(value)
        {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours} hours and ${minutes} minutes`;
        }    
 
    },
}

</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

th, td {
  text-align: center;
  padding: 1px;
  border: 1px solid #3b4253;
}

th {
  background-color: #4d148c;
  color: white;
}

h5 {
    text-align: center;
}

span {
    font-weight: bold;
}
</style>